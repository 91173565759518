import React from 'react'
import Layout from '../components/App/Layout'
import PageBanner from '../components/Common/PageBanner'
import ForgotForm from '../components/ProfileAuthentication/ForgotForm'
import { navigate } from 'gatsby'

if (typeof window !== 'undefined' && localStorage.getItem('user')) navigate('/')

const Forgot = () => {
    return (
        <Layout title="Recuperar contraseña">
            <PageBanner
                pageTitle='Recuperar contraseña'
                homePageText='Inicio'
                homePageUrl='/'
                activePageText='Recuperar contraseña'
            />
            <ForgotForm />
        </Layout>
    )
}

export default Forgot