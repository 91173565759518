import React from 'react'
import { Link } from 'gatsby'
import { useState } from 'react'
import Axios from 'axios'
import { Snackbar } from '@material-ui/core'
import MuiAlert from '@mui/material/Alert'

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

const ForgotForm = () => {
    const [user, setUser] = useState({
        email: ''
    })

    const [error, setError] = useState(null)

    const onInputChange = e => {
        setError(null)
        setUser({ ...user, [e.target.name]: e.target.value })
    }

    const signin = () => {
        setError(user.password === '' ? 'Rellena todos los campos' : null)
        if (user.password !== '') {
            Axios(`${process.env.BACKEND_URL}/sanctum/csrf-cookie`).then(() => {
                Axios.post(`${process.env.API_URL}/forgot`, user, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then(response => {
                    localStorage.setItem('user', JSON.stringify(response.data))
                    window.location.href = '/'
                }).catch(error => {
                    setError(error.response.data.message)
                })
            })

        }
    }

    const handleClose = () => {
        setError(null)
    }

    return (
        <div className='signin-area ptb-100'>
            <div className='container'>
                <div className='login-form'>
                    <form>
                        <div className='form-group'>
                            <label htmlFor='email'>Correo Electrónico</label>
                            <input type='text' name='email' className='form-control' placeholder='Correo Electrónico' onChange={e => onInputChange(e)} />
                        </div>
                        <div className='row align-items-center'>
                            <div className='col-lg-6 col-md-6 col-sm-6'>
                                <Link to='/signup' className='align-self-center'>Regístrate</Link>
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-6 lost-your-password-wrap'>
                                <Link to='/signin' className='lost-your-password'>
                                    Inicia sesión
                                </Link>
                            </div>
                        </div>
                        <button type='button' onClick={signin}>Iniciar Sesión</button>
                    </form>
                    <Snackbar open={error !== null} autoHideDuration={6000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity='error' sx={{ width: '100%' }}>
                            {error}
                        </Alert>
                    </Snackbar>
                </div>
            </div>
        </div>
    )
}

export default ForgotForm